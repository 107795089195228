.quote-div {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)), url("../../assets/img/bg-2.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: max-content;
    justify-content: center;

    .head {
        height: 100%;
        display: flex;

        .quote {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: white;

            p {
                text-align: center;
                font-size: 40px;
                font-family: 'Cormorant Garamond', serif;
            }

            span {
                text-align: center;
                font-family: 'Rubik', sans-serif;
                font-size: 23px;
            }
        }
    }
}

.cards_move {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;
}

.card__certificates {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    width: 190px;
    height: 254px;
    border-radius: 30px;
    background: #212121;
    box-shadow: 15px 15px 30px rgb(25, 25, 25),
        -15px -15px 30px rgb(60, 60, 60);


}

.card__certificates:hover {
    transform: scale(1.2);
    transition: 0.5s ease;
    background-color: rgba(46, 88, 86, 0.7);
}

.title__certificates{
text-align: center;
font-size: 40px;
font-family: 'Cormorant Garamond', serif;
color: white;
margin-bottom: 5rem;
}


@media only screen and (max-width:566px) {
    .cards_move {
        justify-content: center !important;
    }
}