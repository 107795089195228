.navbar-toggler{
  border-color: #000000 !important;
  transition: all 0.5s ease;
  &-icon{
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important
  }
}
    
@media only screen and (max-width:700px){
  
  #root .contact-div .footer{
    bottom:-20px;
    font-size: smaller !important;
  }
  .timeline{
      &-heading-div{
          display: block;
      }
  }
}

@media only screen and (max-width:500px){
  .bg-div .head-div-second-text, .bg-div .head-div-third-text{
    font-size: xx-large;
    padding-left: 5px !important;
  }
  
  .bg-div .head-div-fourth-text{
    font-size: medium;
  }

  .bg-div .social-media{
    left:0;
  }
  
  // #root p,span{
  //   font-size: medium !important;
  // }

  .heading{
    font-size: xx-large !important;
  }

.icons__email{
  display: none;
}

  .header-nav .nav-link{
    width: 100%;
    &:hover{
      background-color: rgba(0,0,0,0.1);
    }

  }

  // .header-nav .navbar-toggler-icon{
  //   margin-bottom: 5px;
  //   margin-right: 4px;
  //   background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30'%3E%3Cpath stroke='rgba(0,0,0,0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  // }
  
  .header-nav .navbar-toggler{
    border-color: black;
    background-color: unset;
  }
}

@media only screen and (max-width:992px){
  .header-nav.sticky{
      .navbar-toggler{
      border-color: #000000 !important;
      transition: all 0.5s ease;
      &-icon{
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important
      }
    }
  }
}


@media only screen and (max-width:769px){
    .card{
        height:300px;
    }
    .header-nav{
        .navbar-nav{
            align-items: center;
            margin-right: 0 !important;
        }
    }
    .timeline .timeline-item .timeline-panel{
        width: 100%;
    }

    #root .header-nav{
      background-color: white !important;
    }

    .header-nav .brand-name,.header-nav .nav-link{
      color:black !important;
    }

}

@media only screen and (max-device-width: 1366px) {
  .bg-div {
    background-attachment: scroll;
  }
  .main-div{
    padding:30px;
  }
}

