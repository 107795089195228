.bg-div{
    background:linear-gradient(0deg, rgba(11, 11, 13, 0.5), rgba(11, 11, 13, 0.6)),  url("../../assets/img/bg-6.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
    .head{
        font-family:  'Rubik', sans-serif;
        color:white;
        position: absolute;
        top: 50%;
        left: 0px;
        right: 0px;
        transform: translateY(-50%);
        z-index: 1;
        line-height: 0.1vh;
        min-height: 100vh;
        display: flex;
        &-div{
            &-first-text{
                font-family: 'Courier Prime', monospace;;
                font-size: 18px !important;
                color: #63c9c6;
                padding-left: 5px;
            }
            &-second-text,&-third-text{
                font-size: 75px;
                padding: 0 !important;
            }
            &-third-text{
                color: #bae8e8 !important;
            }
            &-fourth-text{
                max-width: 500px;
                font-size: 17px;
                margin:20px 0 0;
                line-height: 1.5;
                white-space: pre-wrap;
                padding-left: 5px;
            }
        }
    }
    .scroll-btn {
        display: block;
        position: absolute;
        bottom:0;
        left: 0;
        right: 0;
        text-align: center;
    }
    .scroll-btn > *:hover,
    .scroll-btn > *:focus,
    .scroll-btn > *.active {
        color: #ffffff;
    }
    .scroll-btn > *:hover,
    .scroll-btn > *:focus,
    .scroll-btn > *:active,
    .scroll-btn > *.active {
        opacity: 0.8;
        filter: alpha(opacity=80);
    }
    .scroll-btn .mouse {
        position: relative;
        display: block;
        width: 35px;
        height: 55px;
        margin: 0 auto 20px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        border: 3px solid white;
        border-radius: 23px;
    }
    .scroll-btn .mouse > * {
        position: absolute;
        display: block;
        top: 29%;
        left: 50%;
        width: 8px;
        height: 8px;
        margin: -4px 0 0 -4px;
        background: white;
        border-radius: 50%;
        -webkit-animation: ani-mouse 2.5s linear infinite;
        -moz-animation: ani-mouse 2.5s linear infinite;
        animation: ani-mouse 2.5s linear infinite;
    }
    .social-media{
        width: 40px;
        position: fixed;
        bottom: 0px;
        left: 40px;
        right: auto;
        z-index: 10;
        color: #ffffff;
        &-list{
            display: flex;
            flex-direction: column;
            -webkit-box-align: center;
            align-items: center;
            padding: 0px;
            margin: 0px;
            list-style: none;
            &::after{
                content: "";
                display: block;
                width: 1px;
                height: 90px;
                margin: 0px auto;
                background-color: #fff;
            }
            a{
                display: inline-block;
                text-decoration: none;
                text-decoration-skip-ink: auto;
                color: inherit;
                position: relative;
                transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
                cursor: pointer;
                &:hover{
                    color: #29a19c;
                }
            }
            &-link{
                padding:10px;
                .media-icons{
                    width:24px;
                    height:24px;
                    fill: currentColor;
                }
                &:hover{
                    transform: translateY(-3px);
                }
            }
        }
    }
}

//mouse animation

@-webkit-keyframes ani-mouse {
	0% {
	opacity: 1;
	top: 29%;
	}
	15% {
	opacity: 1;
	top: 50%;
	}
	50% {
	opacity: 0;
	top: 50%;
	}
	100% {
	opacity: 0;
	top: 29%;
	}
}
@-moz-keyframes ani-mouse {
	0% {
	opacity: 1;
	top: 29%;
	}
	15% {
	opacity: 1;
	top: 50%;
	}
	50% {
	opacity: 0;
	top: 50%;
	}
	100% {
	opacity: 0;
	top: 29%;
	}
}

@keyframes ani-mouse {
	0% {
	opacity: 1;
	top: 29%;
	}
	15% {
	opacity: 1;
	top: 50%;
	}
	50% {
	opacity: 0;
	top: 50%;
	}
	100% {
	opacity: 0;
	top: 29%;
	}
}
