.timeline {
    list-style: none;
    padding: 20px;
    color:black;
    position: relative;
	&:before {
        top: 40px;
        bottom: 0;
        position: absolute;
        content: " ";
        width: 3px;
        background-color: #f2f3f7;
        left: 0;
        margin-left: -1.5px;
    }
    &-body{
        p{
            line-height: 1.4;
        }
    }
    .text{
        margin:-2px 0 14px 0;
    }
    &-heading-div{
        display: flex;
        align-items: center;
        justify-content: space-between;
        span{
            display: none;
        }
    }
	.timeline-item {
        margin-bottom: 20px;
        position: relative;
		&:before,
		&:after {
            content: "";
            display: table;
        }
		&:after {
            clear: both;
        }
		.timeline-badge {
            color: #f2f3f7;
            width: 54px;
            height: 54px;
            line-height: 45px;
            font-size: 22px;
            text-align: center;
            position: absolute;
            top: 18px;
            left: -21px;
            margin-left: -25px;
            background-color:rgb(66, 164, 160);
			border: 3px solid #f2f3f7;
            z-index: 100;
            border-top-right-radius: 50%;
            border-top-left-radius: 50%;
            border-bottom-right-radius: 50%;
            border-bottom-left-radius: 50%;
            &:hover{
                background-color: #63c9c6;
                transition: all 0.3 ease-in-out;
            }
			.glyphicon {
				top: 2px;
				left: 0px;
			}
        }
		.timeline-panel {
            position: relative;
            width: 80%;
            height: 100%;
            max-height: fit-content;
            float: left;
			right: 16px;
            border: 1px solid #f2f3f7;
			background: #f2f3f7;
            border-radius: 2px;
            padding: 20px;
            -webkit-box-shadow: 0 1px 6px rgba(255, 255, 255, 0.175);
            box-shadow: 0 1px 6px rgba(255, 255, 255, 0.175);
			&:before {
                position: absolute;
                top: 0;
                right: -16px;
                display: inline-block;
                border-top: 16px solid transparent;
                border-left: 16px solid #f2f3f7;
                border-right: 0 solid #f2f3f7;
                border-bottom: 16px solid transparent;
                content: " ";
            }
			.timeline-title {
    			margin-top: 0;
    			color: inherit;
			}
			.timeline-body > p,
			.timeline-body > ul {
    			margin-bottom: 0;
			}
			.timeline-body > p + p {
        		margin-top: 5px;
    		}
        }
    }
	.timeline-item{
		.timeline-panel {
            background-color: #f2f3f7;
            top:27px;
			left: 40px;
			&:before {
				border-left-width: 0;
				border-right-width: 14px;
				left: -14px;
				right: auto;
			}
		}
	}
}