.card {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 500px;
    border-radius: 30px;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.5s ease;
    backface-visibility: hidden;
    .overlay{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: .5s ease;
        background-color: rgba(46, 88, 86, 0.7);
    }
    img {
        position: absolute;
        height:100% !important;
        width: auto;
        z-index: 0;
        transition: all 0.5s ease;
    }
    .con-text {
        position: absolute;
        z-index: 30;
        width:80%;
        bottom: -200px;
        color: #fff;
        padding: 20px;
        padding-bottom: 180px;
        background: linear-gradient(180deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.9) 100%);
        p {
            display: flex;
            flex-direction: column;
            text-align: left;
            font-size: 1rem;
            opacity: 0;
            margin-bottom: -200px;
            transition: all 0.5s ease;
        }
    }
    .ul {
        position: absolute;
        right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 40;
        border-radius: 14px;
        padding-left: 0;
        padding-bottom: 8px;
        padding-top: 8px;
        top: 0;
        opacity: 0;
        transform: translate(100%);
        transition: all 0.5s ease;
        li {
            background: #fff;
            list-style: none;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0.7;
            transition: all 0.5s ease;
            backface-visibility: hidden;
            a{
                position: absolute;
                width: 100%;
            }
            svg{
                width: 100%;
            }
            &:last-child{
                border-radius: 0 0 12px 12px;
            }
            &:first-child{
                border-radius: 12px 12px 0 0;   
            }
            &:hover{
                opacity: 1;
                transform: translate(-7px, -4px);
                border-radius: 6px;
            }
        }
    }
    &:hover{
        transform: scale(0.9);
        .overlay{
            opacity: 1;
        }
        .ul {
            transform: translate(0);
            opacity: 1;
        }
        .con-text p {
            margin-bottom: 20px;
            opacity: 1;
            transition: all 0.5s ease;
        }
    }

}